import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ApiService from '../../../services/ApiService'
import {
    setSelectedMenu,
    setSelectedSubmenu
} from '../../../actions/index'
import FooterPanelAdmin from './partials/FooterPanelAdmin'

const FooterAdministrator = (props) => {

    const { footer } = props

    const [temporalResource, setTemporalResource] = useState(footer);
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState({ title: "", message: "", color: "" });

    useEffect(() => {
        window.scrollTo(0, 0)
        if(props.selectedMenu !== 'Admin') props.dispatchSetSelectedMenu('Admin')
        props.dispatchSetSelectedSubmenu('FooterAdministrator')
    },[])

    useEffect(() => {
      setTemporalResource(footer);
    }, [footer]);

    const handleSaveResource = async (information, links, traductions) => {
        const newResource = Object.assign({}, footer)
        newResource.typeOfResource = 'Footer'
        newResource.Traductions = traductions
        newResource.Information = information
        newResource.Links = links
        console.log('newResource: ', newResource);
        try {
            const response = ApiService.updateResource(newResource)
            if (response) {
                setToastContent({ title: "Success", message: "Resource updated successfully", color: "success" })
                setShowToast(true)
            } else {
                setToastContent({ title: "Error", message: "Failed to update the resource", color: "danger" })
                setShowToast(true)
            }
        } catch (error) {
            console.error("Error updating the page:", error)
            setToastContent({ title: "Error", message: "Error updating the footer", color: "danger" })
            setShowToast(true)
        }
     
    }

    return (
        <div>
            <FooterPanelAdmin traductions={temporalResource.Traductions} links={temporalResource.Links} information={temporalResource.Information} handleSaveResource={handleSaveResource} />
            <div className={`toast align-top bg-${toastContent.color} ${showToast ? 'show' : ''}`} role="alert" aria-live="assertive" aria-atomic="true" style={{position: 'fixed', top: '20px', right: '20px', zIndex: 1050}}>
                <div className="toast-header">
                <strong className="me-auto">{toastContent.title}</strong>
                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
                </div>
                <div className="toast-body">
                {toastContent.message}
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => (
    {
        dispatchSetSelectedMenu: value => dispatch(setSelectedMenu(value)),
        dispatchSetSelectedSubmenu: value => dispatch(setSelectedSubmenu(value))
    }
)

const mapStateToProps = state => ({
    selectedMenu: state.selectedMenu,
    languageWeb: state.languageWeb,
    footer: state.resources.Footer
})

export default connect(mapStateToProps, mapDispatchToProps)(FooterAdministrator)