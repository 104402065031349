import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  setSelectedMenu,
  setSelectedSubmenu
} from '../../../actions'
import ApiService from '../../../services/ApiService'
import EntityPanelAdmin from './partials/EntityPanelAdmin'
import InspiradoresPanelAdmin from './partials/InspiradoresPanelAdmin'
import WhoWeArePanelAdmin from './partials/WhoWeArePanelAdmin'

const EntityAdministrator = (props) => {

    const { entity } = props

    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState({ title: "", message: "", color: "" });

    useEffect(() => {
        window.scrollTo(0, 0)
        if(props.selectedMenu !== 'Admin') props.dispatchSetSelectedMenu('Admin')
        props.dispatchSetSelectedSubmenu('EntityAdministrator')
    },[])

    const handleSaveTraductions = async (newTraductions) => {
        const newEntityPage = Object.assign({}, entity);
        newEntityPage.Traductions = newTraductions;
        newEntityPage.nameOfThePage = 'Entity';
        try {
          const response = await ApiService.updatePage(newEntityPage);
          if (response) {
            setToastContent({ title: "Success", message: "Page updated successfully.", color: "success" });
            setShowToast(true);
          } else {
            setToastContent({ title: "Error", message: "Failed to update the page.", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          setToastContent({ title: "Error", message: "Error updating the page.", color: "danger" });
          setShowToast(true);
        }
    }

    const handleSaveTraductionsAndInformation = async (newTraductions, newInformation) => {
        const newEntityPage = Object.assign({}, entity);
        newEntityPage.Traductions = newTraductions;
        newEntityPage.Information = newInformation;
        newEntityPage.nameOfThePage = 'Entity';
        try {
          const response = await ApiService.updatePage(newEntityPage);
          if (response) {
            setToastContent({ title: "Success", message: "Page updated successfully.", color: "success" });
            setShowToast(true);
          } else {
            setToastContent({ title: "Error", message: "Failed to update the page.", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          setToastContent({ title: "Error", message: "Error updating the page.", color: "danger" });
          setShowToast(true);
        }
    }

    const handleSaveTraductionsAndInspirational = async (newTraductions, inspirers) => {
        const newEntityPage = Object.assign({}, entity);
        newEntityPage.Traductions = newTraductions;
        newEntityPage.Information.Inspiring = inspirers;
        newEntityPage.nameOfThePage = 'Entity';
        try {
          const response = await ApiService.updatePage(newEntityPage);
          if (response) {
            setToastContent({ title: "Success", message: "Page updated successfully.", color: "success" });
            setShowToast(true);
          } else {
            setToastContent({ title: "Error", message: "Failed to update the page.", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          setToastContent({ title: "Error", message: "Error updating the page.", color: "danger" });
          setShowToast(true);
        }
    }

    return (
        <>
          <EntityPanelAdmin traductions={entity.Traductions} handleSaveTraductions={handleSaveTraductions} />
          <WhoWeArePanelAdmin traductions={entity.Traductions} information={entity.Information} handleSaveTraductionsAndInformation={handleSaveTraductionsAndInformation} />
          <InspiradoresPanelAdmin inspiring={entity.Information.Inspiring} traductions={entity.Traductions} handleSaveTraductionsAndInspirational={handleSaveTraductionsAndInspirational} />
           <div className={`toast align-top bg-${toastContent.color} ${showToast ? 'show' : ''}`} role="alert" aria-live="assertive" aria-atomic="true" style={{position: 'fixed', top: '20px', right: '20px', zIndex: 1050}}>
                <div className="toast-header">
                <strong className="me-auto">{toastContent.title}</strong>
                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
                </div>
                <div className="toast-body">
                {toastContent.message}
                </div>
            </div>
        </>

    )
    
}

const mapDispatchToProps = dispatch => (
    {
        dispatchSetSelectedMenu: value => dispatch(setSelectedMenu(value)),
        dispatchSetSelectedSubmenu: value => dispatch(setSelectedSubmenu(value))
    }
)

const mapStateToProps = state => ({
    selectedMenu: state.selectedMenu,
    languageWeb: state.languageWeb,
    entity: state.pages.Entity
})

export default connect(mapStateToProps, mapDispatchToProps)(EntityAdministrator)