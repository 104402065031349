import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ApiService from '../../../services/ApiService'
import {
    setSelectedMenu,
    setSelectedSubmenu
} from '../../../actions/index'
import SupportPanelAdmin from './partials/SupportPanelAdmin'
import BlockOfLogos from './partials/BlockOfLogos'

const SupportAdministrator = (props) => {

    const { support } = props

    const [temporalPage, setTemporalPage] = useState(support);
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState({ title: "", message: "", color: "" });

    useEffect(() => {
        window.scrollTo(0, 0)
        if(props.selectedMenu !== 'Admin') props.dispatchSetSelectedMenu('Admin')
        props.dispatchSetSelectedSubmenu('SupportsAdministrator')
    },[])

    useEffect(() => {
        setTemporalPage(support);
    }, [support]);

    const handleSaveTraductions = async (newTaductions) => {
        const newPage = Object.assign({}, support);
        newPage.Traductions = newTaductions;
        newPage.nameOfThePage = 'Supports';
        try {
          const response = await ApiService.updatePage(newPage);
          if (response) {
            setToastContent({ title: "Success", message: "Page updated successfully", color: "success" });
            setShowToast(true);
          } else {
            setToastContent({ title: "Error", message: "Failed to update the page", color: "danger" });
            setShowToast(true);
          }
        } catch (error) {
          setToastContent({ title: "Error", message: "An error occurred while updating the page", color: "danger" });
          setShowToast(true);
        }
    }

    const handleSaveBlockOfLogos = async (typeOfBlock, logos) => {
      const newPage = Object.assign({}, support)
      if (typeOfBlock === 'First Block') newPage.Images.FirstBlock = logos
      if (typeOfBlock === 'Second Block') newPage.Images.SecondBlock = logos
      if (typeOfBlock === 'Third Block') newPage.Images.ThirdBlock = logos
      newPage.nameOfThePage = 'Supports'
      try {
        const response = await ApiService.updatePage(newPage)
        if (response) {
          setToastContent({ title: "Success", message: "Logos updated successfully", color: "success" })
          setShowToast(true);
        } else {
          setToastContent({ title: "Error", message: "Failed to update the logos", color: "danger" })
          setShowToast(true);
        }
      } catch (error) {
        setToastContent({ title: "Error", message: "An error occurred while updating the logos", color: "danger" })
        setShowToast(true);
      }
    }

    return (
        <div>
          <SupportPanelAdmin traductions={temporalPage.Traductions} handleSaveTraductions={handleSaveTraductions} />
          <BlockOfLogos typeOfBlock={'First Block'} logos={temporalPage.Images.FirstBlock} handleSaveBlockOfLogos={handleSaveBlockOfLogos} />
          <BlockOfLogos typeOfBlock={'Second Block'} logos={temporalPage.Images.SecondBlock} handleSaveBlockOfLogos={handleSaveBlockOfLogos} />
          <BlockOfLogos typeOfBlock={'Third Block'} logos={temporalPage.Images.ThirdBlock} handleSaveBlockOfLogos={handleSaveBlockOfLogos} />  
          <div className={`toast align-top bg-${toastContent.color} ${showToast ? 'show' : ''}`} role="alert" aria-live="assertive" aria-atomic="true" style={{position: 'fixed', top: '20px', right: '20px', zIndex: 1050}}>
              <div className="toast-header">
              <strong className="me-auto">{toastContent.title}</strong>
              <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
              </div>
              <div className="toast-body">
              {toastContent.message}
              </div>
          </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => (
    {
        dispatchSetSelectedMenu: value => dispatch(setSelectedMenu(value)),
        dispatchSetSelectedSubmenu: value => dispatch(setSelectedSubmenu(value))
    }
)

const mapStateToProps = state => ({
    selectedMenu: state.selectedMenu,
    languageWeb: state.languageWeb,
    support: state.pages.Supports
})

export default connect(mapStateToProps, mapDispatchToProps)(SupportAdministrator)