import React, { useState } from 'react'

const BlockOfLogos = (props) => {
    const { handleSaveBlockOfLogos, logos, typeOfBlock } = props

    const [temporalLogosInformation, setTemporalLogosInformation] = useState(logos)
    const [openModal, setOpenModal] = useState(false)
    const [selectedLogo, setSelectedLogo] = useState(null)

    const getTitleOfPanel = () => {
        switch (typeOfBlock) {
            case 'First Block':
                return 'Primer bloc de logos'
            case 'Second Block':
                return 'Segon bloc de logos'
            case 'Third Block':
                return 'Tercer bloc de logos'
            default:
                return ''
        }
    }

    const handleSaveTheLogoSection = () => {
        const lastSixLogos = temporalLogosInformation.slice(-6)
        const allEmpty = lastSixLogos.every(logo => logo.Link === '' && logo.Description === '')
    
        if (allEmpty) {
            const updatedLogosInformation = temporalLogosInformation.slice(0, -6);
            setTemporalLogosInformation(updatedLogosInformation)
            handleSaveBlockOfLogos(typeOfBlock, updatedLogosInformation)
        } else {
            handleSaveBlockOfLogos(typeOfBlock, temporalLogosInformation)
        }
    }

    const renderLogosInformationInTable = () => {
        const rows = [];

        for (let i = 0; i < temporalLogosInformation.length; i += 6) {
            const row = [];
            for (let j = i; j < i + 6 && j < temporalLogosInformation.length; j++) {
                const collaborator = temporalLogosInformation[j]
                const link = collaborator.Link || ''
                const description = collaborator.Description || ''

                row.push(
                    <div class="card col-2 d-flex flex-column" key={j}>
                        {link && (
                            <img src={link} alt={description} />
                        )}
                        <div class="card-body d-flex flex-column justify-content-between">
                            <div class="d-flex justify-content-center mt-auto">
                            <button className="btn btn-primary" onClick={() => handleEditLogoInformation({ ...collaborator, index: j })}>Editar</button>
                            </div>
                        </div>
                    </div>
                );
            }
            const rowStyle = i === 0 ? { paddingTop: '20px' } : {};
            rows.push(
                <div className="row g-0 m-0 p-0" style={rowStyle} key={i}>
                    {row}
                </div>
            );
        }
        return rows;
    }

    const handleEditLogoInformation = (logo) => {
        setSelectedLogo(logo)
        setOpenModal(true)
    }

    const handleDeleteLogo = () => {
        const updatedLogosInformation = temporalLogosInformation.map((logo, index) => {
            if (index === selectedLogo.index) {
                return {
                    ...logo,
                    Link: '',
                    Description: ''
                }
            }
            return logo
        })
        setTemporalLogosInformation(updatedLogosInformation)
        closeModal()
    }

    const handleSaveLogo = () => {
        const updatedLogosInformation = temporalLogosInformation.map((logo, index) => {
            if (index === selectedLogo.index) {
                return {
                    ...logo,
                    Link: selectedLogo.Link,
                    Description: selectedLogo.Description
                }
            }
            return logo
        })
        setTemporalLogosInformation(updatedLogosInformation)
        closeModal()
    }

    const closeModal = () => {
        setSelectedLogo(null)
        setOpenModal(false)
    }

    const handleAddRow = () => {
        const emptyLogo = { Link: '', Description: '' }
        const newRowOfLogos = Array(6).fill(emptyLogo)
        setTemporalLogosInformation(prevLogos => [...prevLogos, ...newRowOfLogos]);
    }

    // Verificar si al menos un logo tiene Link y Description no vacíos
    const canAddRow = temporalLogosInformation.slice(-6).some(logo => logo.Link && logo.Description);

    return (
        <div className="card m-4">
            <div className="card-header d-flex justify-content-between align-items-center py-1">
                <span className="me-auto font-weight-bold">{getTitleOfPanel()}</span>
                <button type="button" onClick={handleSaveTheLogoSection} className="btn btn-success btn-rounded btn-sm">
                    <i className="bi bi-save"></i> <span style={{ paddingLeft: '10px' }}>Guardar</span>
                </button>
            </div>
            <div className="card-body">
                {renderLogosInformationInTable()}
            </div>
            <div className="card-footer">
                <button type="button" onClick={handleAddRow} className="btn btn-primary" disabled={!canAddRow}>Afegir una nova fila</button>
            </div>
            {selectedLogo && (
                <div className="modal" style={{ display: openModal ? 'block' : 'none' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Editar imatge</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="linkInput" className="form-label">Link:</label>
                                    <input type="text" className="form-control" id="linkInput" value={selectedLogo.Link} onChange={(event) => setSelectedLogo({...selectedLogo, Link: event.target.value})} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="descriptionInput" className="form-label">Descripció:</label>
                                    <input type="text" className="form-control" id="descriptionInput" value={selectedLogo.Description} onChange={(event) => setSelectedLogo({...selectedLogo, Description: event.target.value})} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancelar</button>
                                <button type="button" className="btn btn-danger" onClick={handleDeleteLogo}>Eliminar</button>
                                <button type="button" className="btn btn-primary" onClick={handleSaveLogo}>Guardar canvis</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BlockOfLogos;
