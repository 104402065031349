import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment/moment'
import './Footer.css'

const Footer = (props) => {

  const { languageWeb, footer: { Information, Links, Traductions }, selectedMenu} = props

  const renderDesktopFooter = () => {
    return (
      <div className="Footer_Desktop Footer_Desktop_View">
        <div className='col-12 m-0 p-0'>
            <span className='Footer_Titles_Desktop'>{Traductions[languageWeb].FollowUs}</span>
        </div>
        <div className="col-12 m-0 p-0">
              <div className='Footer_Information_Desktop'>
                <a className="Footer_Links_Desktop" href={Links.Instagram} target="_blank" rel="noopener noreferrer">{Traductions[languageWeb].Instagram}</a><span>, </span>
                {/* <a className="Footer_Links_Desktop" href={Links.Twitter} target="_blank" rel="noopener noreferrer">{Traductions[languageWeb].Twitter}</a><span>, </span> */}
                <a className="Footer_Links_Desktop" href={Links.Youtube} target="_blank" rel="noopener noreferrer">{Traductions[languageWeb].Youtube}</a>
              </div>
          </div>
          <div className='Footer_Second_Title_Padding_Desktop'>
            <div className="col-12 m-0 p-0">
              <span className='Footer_Titles_Desktop'>{Traductions[languageWeb].Contact}</span>
            </div>
          </div>
          <div className='Footer_Information_Email_Padding_Desktop'>
            <div className="col-12 m-0 p-0">
                <span className='Footer_Information_Mobile'>{Information.Mail}</span>
            </div>
          </div>
          <div className='Footer_Information_Address_Padding_Desktop'>
            <div className="col-12 m-0 p-0">
                <span className='Footer_Information_Mobile'>{Information.Address}</span>
            </div>
          </div>
          <div className='Footer_Copyright_Padding_Desktop'>
            <div className="col-12 m-0 p-0">
              <span className='Footer_Copyright_Desktop'>{Information.CopyrightSymbol} {moment().year()} {Information.CopyrightInformation}</span>
            </div>
          </div>
      </div>   
    )
  }

  const renderMobileFooter = () => {
    return (
      <div className="Footer_Mobile Footer_Mobile_View">
          <div className='col-12 m-0 p-0'>
              <span className='Footer_Titles_Mobile'>{Traductions[languageWeb].FollowUs}</span>
          </div>  
          <div className="col-12 m-0 p-0">
              <div className='Footer_Information_Mobile'>
                <a className="FooterLinks_Mobile" href={Links.Instagram} target="_blank" rel="noopener noreferrer">{Traductions[languageWeb].Instagram}</a><span>, </span>
                {/* <a className="FooterLinks_Mobile" href={Links.Twitter} target="_blank" rel="noopener noreferrer">{Traductions[languageWeb].Twitter}</a><span>, </span> */}
                <a className="FooterLinks_Mobile" href={Links.Youtube} target="_blank" rel="noopener noreferrer">{Traductions[languageWeb].Youtube}</a>
              </div>
          </div>
          <div className='Footer_Second_Title_Padding_Mobile'>
            <div className="col-12 m-0 p-0">
              <span className='Footer_Titles_Mobile'>{Traductions[languageWeb].Contact}</span>
            </div>
          </div>
          <div className='Footer_Information_Email_Padding_Mobile'>
            <div className="col-12 m-0 p-0">
                <span className='Footer_Information_Mobile'>conarteinternacional</span>
                <br/>
                <span className='Footer_Information_Mobile'>@gmail.com</span>
            </div>
          </div>
          <div className='Footer_Information_Address_Padding_Mobile'>
          <div className="col-12 m-0 p-0">
              <span className='Footer_Information_Mobile'>Carrer del sol, 8,</span>
               <br/>
               <span className='Footer_Information_Mobile'>17781 Vilamaniscle, Girona</span>
            </div>
          </div>
          <div className='Footer_Copyright_Padding_Mobile'>
            <div className="col-12 m-0 p-0">
              <span className='Footer_Copyright_Mobile'>{Information.CopyrightSymbol} {moment().year()} {Information.CopyrightInformation}</span>
            </div>
          </div>
      </div>
    )
  }

  if (selectedMenu === 'Admin') return null
  if (selectedMenu === 'Arbrar') return null

  return (
    <>
      {renderDesktopFooter()}
      {renderMobileFooter()}
    </>
  )

}

const mapStateToProps = state => ({
  selectedMenu: state.selectedMenu,
  languageWeb: state.languageWeb,
  footer: state.resources.Footer
})

export default connect(mapStateToProps, null)(Footer)