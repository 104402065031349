import React, { useState } from 'react'
import $ from 'jquery'

const FormationProjectsAdmin = (props) => {

  const { projects } = props

  const [selectedLanguageTab, setSelectedLanguageTab] = useState(
      'Catalan'
  )

  const [selectedProjectIndex, setSelectedProjectIndex] = useState(null);

  const [temporalProjects, setTemporalProjects] = useState(() => JSON.parse(JSON.stringify(projects)))

  const handleSelectedLanguageTabChange = (language) => {
      setSelectedLanguageTab(language);
  }

  const renderProjectsTable = (language) => {
      return (
        <div className='row ml-1 mr-1'>
        <div className="d-flex justify-content-end">
          <button className="btn btn-sm btn-primary mt-3" onClick={addNewProject}>
                  <i className="bi bi-plus"></i> <span style={{paddingLeft: '10px'}}>Afegir</span>
          </button>
        </div>
        <div className="table-responsive">
          <table className="table table-sm">
              <thead className="table-primary">
                  <tr>
                      <th className="border-top-0 border-bottom-0" scope="col">Posició</th>
                      <th className="border-top-0 border-bottom-0" scope="col">Títol</th>
                      <th className="border-top-0 border-bottom-0 text-right"></th>
                      <th className="border-top-0 border-bottom-0 text-right"></th>
                      <th className="border-top-0 border-bottom-0 text-right"></th>
                      <th className="border-top-0 border-bottom-0 text-right"></th>
                  </tr>
              </thead>
                  <tbody>
                    {temporalProjects.map((project, index) => {
                      return (
                        <tr key={index} className="animated fadeIn">
                          <td>{`${project.Information.Position}`}</td>
                          <td>{project.Traductions[selectedLanguageTab].Title}</td>
                          <td>
                              <button className="btn btn-sm btn-primary"
                                      disabled={index === 0}
                                      onClick={(e) => {
                                          e.preventDefault()
                                          changeProjectPosition(index, 'Up')
                                      }}>
                                  <i class="bi bi-arrow-up"></i>
                              </button>
                          </td>
                          <td>
                              <button className="btn btn-sm btn-primary"
                                      disabled={(index+1)===temporalProjects.length}
                                      onClick={(e) => {
                                          e.preventDefault()
                                          changeProjectPosition(index, 'Down')
                                      }}>
                                  <i class="bi bi-arrow-down"></i>
                              </button>
                          </td>
                          <td>
                              <button className="btn btn-sm btn-primary"
                                      onClick={(e) => {
                                          e.preventDefault()
                                          handleEditClick(index);
                                      }}>
                                  <i class="bi bi-pencil-square"></i>
                              </button>
                          </td>
                          <td>
                              <button className="btn btn-sm btn-danger"
                                      onClick={(e) => {
                                          e.preventDefault()
                                          removeProjectFromHome(index)
                                      }}>
                                  <i class="bi bi-trash3"></i>
                              </button>
                          </td>
                        </tr>)
                    })}
                </tbody>
            </table>
        </div>
          </div>
      )
  }

  const renderLanguageModalSection = (language) => {
    return (
      <div
          className={`tab-pane fade show ${
              selectedLanguageTab === language ? "active" : ""
          }`}
          id="ex1-tabs-1"
          role="tabpanel"
          aria-labelledby="ex1-tab-1"
        > 
      <div className="row mt-1">
        <div className="col-md-6">
          <div className="form-outline">
            <label className="form-label" htmlFor="form12">
              Títol
            </label>
            <input
              type="text"
              id="form12"
              className="form-control"
              name="Title"
              value={
                temporalProjects[selectedProjectIndex].Traductions[selectedLanguageTab].Title
              }
              onChange={handleChangeInformation}
            />
            </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-md-12">
          <div className="form-outline">
            <label className="form-label" htmlFor="textAreaExample">
              Descripció
            </label>
            <textarea
              className="form-control"
              id="textAreaExample"
              rows={6}
              name="Description"
              value={
                temporalProjects[selectedProjectIndex].Traductions[selectedLanguageTab].Description
              }
              onChange={handleChangeInformation}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="row mt-2">
          <div className="col-md-3">
                <div className="form-outline">
                    <label>
                        <input 
                            type="checkbox" 
                            checked={temporalProjects[selectedProjectIndex].Information.HasSeeMore} 
                            onChange={handleHasSeeMoreChange}
                        />
                        <span style={{paddingLeft: '5px'}}>Amb enllaç</span>
                    </label>
                </div>
          </div>
      </div>

      { temporalProjects[selectedProjectIndex].Information.HasSeeMore && 
          <div className="row">
            <div className="col-md-3">
              <div className="form-outline">
                <label className="form-label" htmlFor="form12">
                  Text
                </label>
                <input
                  type="text"
                  id="form12"
                  className="form-control"
                  name="SeeMoreInformation"
                  value={
                    temporalProjects[selectedProjectIndex].Traductions[selectedLanguageTab].SeeMoreInformation
                  }
                  onChange={(event) => handleChangeSeeMoreInformation(event)}
                />
                </div>
            </div>
          </div>
        }
        { temporalProjects[selectedProjectIndex].Information.HasSeeMore && 
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="form-outline">
                <label className="form-label" htmlFor="form12">
                  URL
                </label>
                <input
                  type="text"
                  id="form12"
                  className="form-control"
                  name="Link"
                  value={
                    temporalProjects[selectedProjectIndex].Traductions[selectedLanguageTab].Link
                  }
                  onChange={(event) => handleChangeLink(event)}
                />
                </div>
            </div>
          </div>
        }

      {temporalProjects[selectedProjectIndex].Information.Type === 'Video' && <div className="row mt-1">
        <div className="col-md-12">
          <div className="form-outline">
            <label className="form-label" htmlFor="form12">
              Enllaç de Youtube
            </label>
            <input
              type="text"
              id="form12"
              className="form-control"
              name="Video"
              value={
                temporalProjects[selectedProjectIndex].Traductions[selectedLanguageTab].Video
              }
              onChange={handleChangeInformation}
            />
            </div>
        </div>
      </div>}

    </div>
    )
  }

  const handleChangeInformation = (event) => {
    // Destructure the name and value from the event target (which is the input)
    const { name, value } = event.target;

    // Create a copy of temporalProjects to avoid directly mutating the state
    const updatedProjects = [...temporalProjects];

    // Update the specific field based on input's name
    updatedProjects[selectedProjectIndex].Traductions[selectedLanguageTab][name] = value;

    // Update the state with the new array (you'll need a state setter if temporalProjects is a state variable)
     setTemporalProjects(updatedProjects);
  }

  const handleTypeChange = (type) => {
    if (selectedProjectIndex !== null) {
        // Hacer una copia de los proyectos temporales y del proyecto seleccionado
        const updatedProjects = [...temporalProjects];
        const updatedProject = { ...updatedProjects[selectedProjectIndex] };

        // Modificar el tipo de información
        updatedProject.Information.Type = type;

        // Actualizar el proyecto en la lista y luego el estado
        updatedProjects[selectedProjectIndex] = updatedProject;
        setTemporalProjects(updatedProjects);
    }
  };

  const renderModalEditProject = () => {
    return (
      <div className="modal fade" id="editProjectModal" tabIndex="-1" aria-labelledby="editProjectModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="editProjectModalLabel">Editar</h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <div className="modal-body">
                          {selectedProjectIndex !== null && (
                              <>
                                  <div>
                                    <div className='row text-left'>
                                        <div className="col-md-6">
                                            <label htmlFor="typeSelect">Selecciona el tipus de contingut:</label>
                                            <select
                                                className="form-control"
                                                id="typeSelect"
                                                value={temporalProjects[selectedProjectIndex].Information.Type}
                                                onChange={(e) => handleTypeChange(e.target.value)}
                                            >
                                                <option value="Images">Imatges</option>
                                                <option value="Video">Vídeo</option>
                                            </select>
                                        </div>
                                    </div>

                                    <ul className="nav nav-tabs mb-3 mt-3" id="ex1" role="tablist">
                                      <li className="nav-item" role="presentation">
                                        <button
                                          className={`nav-link ${
                                            selectedLanguageTab === 'Catalan' ? "active" : ""
                                          }`}
                                          id="ex1-tab-1"
                                          data-mdb-toggle="tab"
                                          role="tab"
                                          aria-controls="ex1-tabs-1"
                                          aria-selected={selectedLanguageTab === 'Catalan'}
                                          onClick={(event) => handleSelectedLanguageTabChange('Catalan')}
                                        >
                                          Català
                                        </button>
                                      </li>
                                      <li className="nav-item" role="presentation">
                                        <button
                                          className={`nav-link ${
                                            selectedLanguageTab === 'Spanish' ? "active" : ""
                                          }`}
                                          id="ex1-tab-4"
                                          data-mdb-toggle="tab"
                                          role="tab"
                                          aria-controls="ex1-tabs-4"
                                          aria-selected={selectedLanguageTab === 'Spanish'}
                                          onClick={(event) => handleSelectedLanguageTabChange('Spanish')}
                                        >
                                          Castellà
                                        </button>
                                      </li>
                                      <li className="nav-item" role="presentation">
                                        <button
                                          className={`nav-link ${
                                            selectedLanguageTab === 'English' ? "active" : ""
                                          }`}
                                          id="ex1-tab-2"
                                          data-mdb-toggle="tab"
                                          role="tab"
                                          aria-controls="ex1-tabs-2"
                                          aria-selected={selectedLanguageTab === 'English'}
                                          onClick={(event) => handleSelectedLanguageTabChange('English')}
                                        >
                                          Anglès
                                        </button>
                                      </li>
                                      <li className="nav-item" role="presentation">
                                        <button
                                          className={`nav-link ${
                                            selectedLanguageTab === 'French' ? "active" : ""
                                          }`}
                                          id="ex1-tab-3"
                                          data-mdb-toggle="tab"
                                          role="tab"
                                          aria-controls="ex1-tabs-3"
                                          aria-selected={selectedLanguageTab === 'French'}
                                          onClick={(event) => handleSelectedLanguageTabChange('French')}
                                        >
                                          Francès
                                        </button>
                                      </li>
                                    </ul>
                                    <div className="tab-content" id="ex1-content">
                                      {renderLanguageModalSection('Catalan')}
                                      {renderLanguageModalSection('Spanish')}
                                      {renderLanguageModalSection('English')}
                                      {renderLanguageModalSection('French')}
                                    </div>
                                  </div>

                                  {temporalProjects[selectedProjectIndex].Information.Type === 'Images' && <div className='row mt-2 ml-1 mr-1'>
                                    <div className="d-flex justify-content-end">
                                      <button className="btn btn-sm btn-primary mt-3" onClick={addNewImageToCarousel}>
                                              <i className="bi bi-plus"></i> <span style={{paddingLeft: '10px'}}>Afegir imatge</span>
                                      </button>
                                    </div>
                                    <div className="table-responsive">
                                      <table className="table table-sm">
                                          <thead className="table-primary">
                                              <tr>
                                                <th className="border-top-0 border-bottom-0" scope="col">URL</th>
                                                <th className="border-top-0 border-bottom-0 text-center" scope="col">Posició</th>
                                                <th className="border-top-0 border-bottom-0 text-right"></th>
                                                <th className="border-top-0 border-bottom-0 text-right"></th>
                                                <th className="border-top-0 border-bottom-0 text-right"></th>
                                              </tr>
                                          </thead>
                                              <tbody>
                                                {temporalProjects[selectedProjectIndex].Images.Carousel.map((image, index) => {
                                                  return (
                                                    <tr key={index} className="animated fadeIn">
                                                      <textarea
                                                        className="form-control"
                                                        id="textAreaExample"
                                                        rows={2}
                                                        name="ImageURL"
                                                        value={
                                                          image
                                                        }
                                                        onChange={(e) => handleChangeImageUrl(index,e)}
                                                      ></textarea>
                                                      <td className="text-center">{`${index +1}`}</td>
                                                      <td>
                                                          <button className="btn btn-sm btn-primary"
                                                                  disabled={index === 0}
                                                                  onClick={(e) => {
                                                                      e.preventDefault()
                                                                      changeImageCarouselPosition(index, 'Up')
                                                                  }}>
                                                              <i class="bi bi-arrow-up"></i>
                                                          </button>
                                                      </td>
                                                      <td>
                                                          <button className="btn btn-sm btn-primary"
                                                                  disabled={(index+1)===temporalProjects[selectedProjectIndex].Images.Carousel.length}
                                                                  onClick={(e) => {
                                                                      e.preventDefault()
                                                                      changeImageCarouselPosition(index, 'Down')
                                                                  }}>
                                                              <i class="bi bi-arrow-down"></i>
                                                          </button>
                                                      </td>
                                                      <td>
                                                          <button className="btn btn-sm btn-danger"
                                                                  onClick={(e) => {
                                                                      e.preventDefault()
                                                                      removeImageCarousel(index)
                                                                  }}>
                                                              <i class="bi bi-trash3"></i>
                                                          </button>
                                                      </td>
                                                    </tr>)
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                  </div>}
                              </>
                          )}
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Cancelar</button>
                          <button type="button" className="btn btn-success" onClick={handleSaveModal}>Guardar</button>
                      </div>
                  </div>
              </div>
          </div>
    )
  }

  const addNewImageToCarousel = () => {
    // Añadimos una cadena vacía al array de imágenes
    let updatedProject = {...temporalProjects[selectedProjectIndex]};
    updatedProject.Images.Carousel.push('');
    
    // Actualizamos el estado con el proyecto modificado
    let updatedProjects = [...temporalProjects];
    updatedProjects[selectedProjectIndex] = updatedProject;
    
    setTemporalProjects(updatedProjects);
  }

  const handleChangeImageUrl = (index, e) => {
    // Primero, hacemos una copia del proyecto seleccionado
    let updatedProject = {...temporalProjects[selectedProjectIndex]};
  
    // Actualizamos la URL de la imagen específica en la lista Carousel usando el índice
    updatedProject.Images.Carousel[index] = e.target.value;
  
    // Hacemos una copia de todos los proyectos y actualizamos el proyecto seleccionado
    let updatedProjects = [...temporalProjects];
    updatedProjects[selectedProjectIndex] = updatedProject;
  
    // Actualizamos el estado con los proyectos modificados
    setTemporalProjects(updatedProjects);
  }
  
  const changeImageCarouselPosition = (index, direction) => {
    // Copiamos la lista de imágenes del proyecto seleccionado
    const images = [...temporalProjects[selectedProjectIndex].Images.Carousel];

    // Si el usuario desea mover la imagen hacia arriba y no es el primer elemento
    if (direction === 'Up' && index > 0) {
        const temp = images[index];
        images[index] = images[index - 1];
        images[index - 1] = temp;
    }

    // Si el usuario desea mover la imagen hacia abajo y no es el último elemento
    if (direction === 'Down' && index < images.length - 1) {
        const temp = images[index];
        images[index] = images[index + 1];
        images[index + 1] = temp;
    }

    // Ahora actualizamos la lista de imágenes del proyecto seleccionado
    const updatedProjects = [...temporalProjects];
    updatedProjects[selectedProjectIndex].Images.Carousel = images;

    // Finalmente, actualizamos el estado con el proyecto modificado
    setTemporalProjects(updatedProjects);
  }

  const removeImageCarousel = (index) => {
    // Hacemos una copia del proyecto seleccionado
    const projectCopy = {...temporalProjects[selectedProjectIndex]};

    // Eliminamos la imagen del carrusel según el índice proporcionado
    projectCopy.Images.Carousel.splice(index, 1);

    // Actualizamos el proyecto seleccionado en la lista de proyectos temporales
    const updatedProjects = [...temporalProjects];
    updatedProjects[selectedProjectIndex] = projectCopy;

    // Finalmente, actualizamos el estado con la lista de proyectos modificada
    setTemporalProjects(updatedProjects);
  }

  const createEmptyProject = (index) => {
    return {
      typeOfProject: 'Productions',
        Information: {
            Position: temporalProjects.length + 1,
            Type: 'Images'
        },
        Images: {
          Id: "Productions_" + index,
          Carousel: []
      },
        Traductions: {
            Catalan: {
                Title: '',
                Description: '',
                Video: '',
                SeeMoreInformation: '',
                Link: ''
            },
            Spanish: {
                Title: '',
                Description: '',
                Video: '',
                SeeMoreInformation: '',
                Link: ''
            },
            English: {
                Title: '',
                Description: '',
                Video: '',
                SeeMoreInformation: '',
                Link: ''
            },
            French: {
              Title: '',
              Description: '',
              Video: '',
              SeeMoreInformation: '',
              Link: ''
            }
        }
    };
  }

  const addNewProject = () => {
    const newProject = createEmptyProject(temporalProjects.length);
    const updatedProjects = [...temporalProjects, newProject];
    setTemporalProjects(updatedProjects);
    setSelectedProjectIndex(updatedProjects.length - 1);
    handleEditClick(updatedProjects.length - 1)
  }

  const changeProjectPosition = (index, direction) => {
    const newProjects = [...temporalProjects];  // Crea una copia del array de proyectos
    if ((direction === 'Up' && index === 0) || (direction === 'Down' && index === newProjects.length - 1)) {
        // Si el usuario intenta mover el primer elemento hacia arriba o el último elemento hacia abajo, no hacemos nada
        return;
    }
    
    const projectToMove = newProjects[index];
    
    if (direction === 'Up') {
        // Si estamos moviendo hacia arriba, intercambiamos con el elemento anterior
        newProjects[index] = newProjects[index - 1];
        newProjects[index - 1] = projectToMove;
    } else {
        // Si estamos moviendo hacia abajo, intercambiamos con el elemento siguiente
        newProjects[index] = newProjects[index + 1];
        newProjects[index + 1] = projectToMove;
    }

    // Actualizamos las posiciones
    newProjects.forEach((project, idx) => {
        project.Information.Position = idx + 1;
    });

    setTemporalProjects(newProjects);  // Actualiza el estado con la nueva lista de proyectos
  }

  const removeProjectFromHome = (index) => {
    // Utilizamos filter para obtener un nuevo array sin el proyecto que queremos eliminar
    const newProjects = temporalProjects.filter((_, idx) => idx !== index);

    // Después de eliminar, es recomendable actualizar las posiciones de los proyectos restantes
    newProjects.forEach((project, idx) => {
        project.Information.Position = idx + 1;
    });

    setTemporalProjects(newProjects);  // Actualizamos el estado con la nueva lista de proyectos
  }

  const handleEditClick = (index) => {
    setSelectedProjectIndex(index);
    // Aquí puedes agregar código para abrir el modal de Bootstrap. Por ejemplo:
    $('#editProjectModal').modal('show')
  }

  const handleCloseModal = () => {
    if (selectedProjectIndex !== null) {
      if (selectedProjectIndex >= projects.length) {
          removeProjectFromHome(selectedProjectIndex)
      } else {
          setTemporalProjects(prevProjects => {
            const updatedProjects = [...prevProjects]
            updatedProjects[selectedProjectIndex] = JSON.parse(JSON.stringify(projects[selectedProjectIndex]))
            return updatedProjects
          })
      }
    }
    setSelectedProjectIndex(null)
    $('#editProjectModal').modal('hide')
  }

  const handleSaveModal = () => {
    setSelectedProjectIndex(null)
    $('#editProjectModal').modal('hide')
  }

  const handleHasSeeMoreChange = () => {
    const projectsCopy = [...temporalProjects];
    projectsCopy[selectedProjectIndex].Information.HasSeeMore = !projectsCopy[selectedProjectIndex].Information.HasSeeMore;
    if (!projectsCopy[selectedProjectIndex].Information.HasSeeMore) {
     projectsCopy[selectedProjectIndex].Traductions[selectedLanguageTab].SeeMoreInformation = '';
     projectsCopy[selectedProjectIndex].Traductions[selectedLanguageTab].Link = '';
    }
    setTemporalProjects(projectsCopy);
  }

  const handleChangeSeeMoreInformation = (event) => {
    const projectsCopy = [...temporalProjects];
    projectsCopy[selectedProjectIndex].Traductions[selectedLanguageTab].SeeMoreInformation = event.target.value;
    setTemporalProjects(projectsCopy);
  }

  const handleChangeLink = (event) => {
    const projectsCopy = [...temporalProjects];
    projectsCopy[selectedProjectIndex].Traductions[selectedLanguageTab].Link = event.target.value;
    setTemporalProjects(projectsCopy);
  }

  const handleSaveProjects = () => {
      props.handleSaveProjects(temporalProjects)
      handleCloseModal()
  }


  return (
    <>
      <div className="card m-4">
      <div className="card-header d-flex justify-content-between align-items-center py-1">
        <span className="me-auto">LLISTAT</span>
        <button type="button" onClick={handleSaveProjects} className="btn btn-success btn-rounded btn-sm">
        <i className="bi bi-save"></i> <span style={{paddingLeft: '10px'}}>Guardar</span>
        </button>
      </div>
      <div className="card-body">
        {renderProjectsTable()}
      </div>
    </div>

    {/* Modal */}
    
    {renderModalEditProject()}

    </>
  )
}

export default FormationProjectsAdmin